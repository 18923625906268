



































































































import { Component, Vue } from 'vue-property-decorator'
import InviteConfirm from '@/views/Group/TopPage/MemberList/QR/InviteConfirm.vue'
//@ts-ignore
import _ from 'lodash'
import { emailRegex } from '@/validations/validation'
import GroupMemberService from '@/services/GroupMemberService'

/**
 * f2-304
 * グループ メンバー招待 知人の選択
 */
@Component({ components: { InviteConfirm } })
export default class InvitebyMail extends Vue {
  private mails: IMail[] = []
  private existMail: string[] = []
  private errorLock: boolean = true
  private isError: boolean = false
  private errorMess: string[] = []

  /**
   * add default row
   */
  async addDefaultRow() {
    if (!this.mails.length) {
      this.mails.push({ id: 0, mail: undefined })
    }
  }

  /**
   * Create new row
   */
  async createNewRow(newId: number) {
    let newMails = [...this.mails]
    newMails.push({ id: newId, mail: undefined })
    this.mails = _.sortBy(newMails, ['id'])
    return _.findIndex(this.mails, { id: newId, mail: undefined })
  }

  /**
   * Create new row on enter
   */
  async newRowOnEnter(mail: IMail, index: number) {
    if (mail.mail && mail.mail.trim().length !== 0) {
      let numberBetween = index + 1
      if (this.mails[index + 1]) {
        numberBetween = (mail.id + this.mails[index + 1].id) / 2
      }
      const newIndex: number = await this.createNewRow(numberBetween)
      this.setFocusIndexRow(newIndex)
    }
  }

  /**
   * Delete row
   */
  deleteRow(mail: IMail, index: number) {
    this.mails = this.mails.filter(item => item.id !== mail.id)
    if (this.mails.length === index) {
      this.setFocusToLastRow()
    }
    this.onValidMailInput()
  }

  /**
   * Delete row on backspace
   */
  deleteRowOnBackspace(mail: IMail, index: number) {
    if (this.mails.length === 1) {
      return
    }
    if (mail.mail === undefined) {
      this.mails = this.mails.filter(item => item.id !== mail.id)
      if (this.mails.length === index) {
        this.setFocusToLastRow()
      }
    } else if (mail.mail.trim().length === 0) {
      this.mails[index].mail = undefined
    }
  }

  /**
   * Set row focus by index
   */
  setFocusIndexRow(newIndex: number) {
    ;(this.$refs as any).mailRowInput[newIndex].focus()
  }

  /**
   * Set row forcus top
   */
  async setFocusToLastRow() {
    await this.addDefaultRow()
    ;(this.$refs as any).mailRowInput[this.mails.length - 1].focus()
  }

  /**
   * hanlde mail input change
   */
  handleMailInputChange(event: any, mail: IMail, index: number) {
    if ((event as any).keyCode === 13) {
      this.newRowOnEnter(mail, index)
      return
    }
    if ((event as any).keyCode === 8) {
      this.deleteRowOnBackspace(mail, index)
    }
    this.onValidMailInput()
  }

  /**
   * hanlde paste multiple mails input
   */
  handlePaste(event: any, index: number) {
    // const clipboardData = event.clipboardData.getData('text/plain') as string
    // clipboardData
    //   .split(/\s+/)
    //   .filter(item => item.trim())
    //   .forEach((mail: string, i: number) => {
    //     const randomId = (Math.random() * 30) / Math.random()
    //     if (i === 0) {
    //       this.mails[index].mail = this.mails[index].mail
    //         ? this.mails[index].mail + mail
    //         : mail
    //     } else {
    //       this.mails.push({ id: randomId, mail: mail })
    //     }
    //   })
    // event.preventDefault()
  }

  /**
   * Validate mail input change
   */
  async onValidMailInput() {
    const isNotEmptyEmail =
      !!this.mails.length && !!this.mails[0].mail?.trim().length
    const isWrongEmail = await this.handleWrongEmail()
    const isMailOverSize = await this.handleEmailOverSize()
    const isDulicate = await this.handleDulicateMail()
    const isExistMail = await this.handleExistMail()
    if (
      isNotEmptyEmail &&
      !isExistMail &&
      !isWrongEmail &&
      !isDulicate &&
      !isMailOverSize
    ) {
      this.isError = false
      return true
    }
    this.errorMess = []
    if (!isNotEmptyEmail) {
      this.errorMess.push(this.$t('common.message.mail_empty') as string)
    }
    if (isWrongEmail) {
      this.errorMess.push(this.$t('common.message.mail_wrong') as string)
    }
    if (isMailOverSize) {
      this.errorMess.push(this.$t('common.message.mail_oversize') as string)
    }
    if (isDulicate) {
      this.errorMess.push(this.$t('common.message.mail_duplicate') as string)
    }
    if (isExistMail) {
      this.errorMess.push(this.$t('common.message.mail_exist') as string)
    }
    this.isError = true
    return false
  }

  /**
   * handle wrong mail regex
   */
  async handleWrongEmail() {
    let valid: boolean[] = []
    this.mails.map((item: IMail) => {
      valid.push(item.mail ? emailRegex.test(item.mail) : true)
    })
    return !valid.every(item => item)
  }

  /**
   * Handle mail over length
   */
  async handleEmailOverSize() {
    let valid: boolean[] = []
    this.mails.map((item: IMail) => {
      valid.push(item.mail ? item.mail.length <= 100 : true)
    })
    return !valid.every(item => item)
  }

  /**
   * handle dulicate mail
   */
  async handleDulicateMail() {
    let valid: boolean[] = []
    this.mails.map((item: IMail) => {
      const cloneMails = this.mails.filter(i => i.id !== item.id)
      valid.push(!!cloneMails.find(i => i.mail?.toLowerCase() === item.mail?.toLowerCase()))
    })
    return valid.some(item => item)
  }

  /**
   * handle exist mail
   */
  async handleExistMail() {
    return this.mails.some(item =>
      this.existMail.includes(item.mail ? item.mail.toLowerCase() : '')
    )
  }

  /**
   * text danger wrong mail regex
   */
  textDangerWrongEmail(mail: IMail) {
    return mail.mail ? !emailRegex.test(mail.mail) : false
  }

  /**
   * text danger mail size
   */
  textDangerMailSize(mail: IMail) {
    const inputMail = mail.mail ? mail.mail : ''
    return inputMail.length > 100
  }

  /**
   * text danger dulicate mail
   */
  textDangerDulicateMail(mail: IMail) {
    const cloneMails = this.mails.filter(item => item.id !== mail.id)
    return !!cloneMails.find(item => item.mail?.toLowerCase() === mail.mail?.toLowerCase())
  }

  /**
   * text danger exist mail
   */
  textDangerExistMail(mail: IMail) {
    return this.existMail.includes(mail.mail ? mail.mail.toLowerCase() : '')
  }

  /**
   * Handle text danger for input
   */
  onClassValidInput(mail: IMail) {
    return (
      (this.textDangerDulicateMail(mail) ||
        this.textDangerWrongEmail(mail) ||
        this.textDangerExistMail(mail) ||
        this.textDangerMailSize(mail)) &&
      !this.errorLock
    )
  }

  /**
   * jupm to invite confirm
   */
  jumpToInviteConfirm() {
    const vm = this
    const filterMail = vm.mails
      .filter(item => item.mail)
      .map(item => (item.mail ? item.mail : ''))
    GroupMemberService.checkExistMails(
      vm.$route.params.groupId,
      filterMail
    ).then(res => {
      if (res.status === 200) {
        // handle change data to lower case
        vm.existMail = res.data.map((item: string) => {
          return item.toLowerCase()
        })

        vm.onValidMailInput().then(valid => {
          if (valid && vm.mails.length) {
            vm.$emit('next', {
              data: filterMail,
              isInviteByMail: true
            })
          } else {
            vm.setFocusToLastRow()
          }
          vm.errorLock = false
        })
      }
    })
  }

  /**
   * jupm to invite auth
   */
  jumpToInviteAuth() {
    this.$router.push({ name: 'group-members-qr-code-invite-auth' })
  }
}

interface IMail {
  id: number
  mail: string | undefined
}
